<template>
    <div>
        <ViewTitle title="佣金查詢" class="viewtitle-container">
            <template v-slot:buttons>
                <div
                    v-if="$isAdmin() && $hasPermission('downloadAdmin')"
                    class="component-container button-container"
                >
                    <Toolbox
                        toolName="下載全佣金明細表"
                        :disabled="mTypeList.length === 0"
                    >
                        <template v-slot:tools>
                            <div
                                v-for="m in mTypeList"
                                :key="m.mType"
                                @click="downloadAdminZip(m.mType)"
                            >
                                <div class="icon download-excel-icon" />
                                <div>{{ m.name }}</div>
                            </div>
                        </template>
                    </Toolbox>
                </div>
            </template>
        </ViewTitle>
        <CommissionFilter
            mode="history"
            :listData="listData"
            :isLoading="isLoadingTable"
            v-model:filterPayloadProp="filterPayload"
            v-model:filterLogProp="filterLog"
            @show-data-table="showDataTable"
        />
        <div class="table-remark margin-bottom-15">
            <ZipPasswordAlert />
        </div>
        <CommissionTable
            :mTypeList="mTypeList"
            :listData="listData"
            :filterLog="filterLog"
            :isLoading="isLoadingTable"
            :canDownloadZip="$hasPermission('downloadSales')"
        />
    </div>
</template>

<script>
import Toolbox from '@/components/Toolbox.vue'
import ViewTitle from '@/components/ViewTitle.vue'
import CommissionFilter from '@/containers/commission/CommissionFilter.vue'
import CommissionTable from '@/containers/commission/CommissionTable.vue'
import ZipPasswordAlert from '@/containers/commission/ZipPasswordAlert.vue'
import {
    getCommissionMTypeAPI,
    getCommissionHistoryAPI,
    // getCommissionPreClosingAPI,
    downloadAdminZipAPI
} from '@/assets/javascripts/api'

export default {
    name: 'CommissionHistory',
    components: {
        Toolbox,
        ViewTitle,
        CommissionFilter,
        CommissionTable,
        ZipPasswordAlert
    },
    methods: {
        showDataTable: async function () {
            try {
                if (!this.filterPayload?.period) {
                    throw new Error('noPeriod')
                }
                this.isLoadingTable = true
                this.$showLoading()

                /* Get Table Data */
                const payload = this.filterPayload
                const res = await this.$getResponse(
                    payload,
                    { apiAlias: 'list', apiName: '佣金明細' },
                    getCommissionHistoryAPI
                )
                this.resCommissionData = res.data
            } catch (error) {
                if (error.message === 'noPeriod') {
                    this.$showErrorMessage('此帳號尚未有可以查看的佣金明細')
                }
                this.resCommissionData = {
                    dataSource: [],
                    lastUpdateTime: ''
                }
            } finally {
                this.apiProcess(this.resCommissionData)
                this.isLoadingTable = false
                this.$hideLoading()
            }
        },
        apiProcess: function (data) {
            this.listData = data.dataSource
            /* Set lastUpdateTime */
            this.lastUpdateTime = new Date(data.lastUpdateTime).toLocaleString()
        },
        downloadAdminZip: async function (mType) {
            if (!this.$hasPermission('downloadAdmin')) {
                this.$showErrorMessage(
                    '此帳號尚無下載全佣金明細表的權限',
                    '請確認您的帳號權限，如有疑問請洽飛鴿'
                )
                return
            }
            this.$setGaEvent('downloadAdminZip', 'click-Button')
            this.$showLoading()
            try {
                const payload = {
                    mType: mType,
                    receiveInfos: this.filterPayload.period.map((period) => {
                        return { period: Number(period) }
                    })
                }
                const res = await downloadAdminZipAPI(payload)
                this.$downloadZipHelper(res, 'zipUrl')
            } catch (error) {
                // eslint-disable-next-line
                console.log(error)
                const fileError = error.response?.data?.displayMsg || '未知錯誤'
                const errorTitle = '下載全佣金明細表檔案時發生錯誤'
                const msg = `${fileError}</br>若持續出現此問題，請聯繫資訊客服`
                this.$showErrorMessage(errorTitle, msg)
            } finally {
                this.$hideLoading()
            }
        },
        setMType: async function () {
            try {
                const res = await getCommissionMTypeAPI()
                this.mTypeList = res.data?.mTypeList
            } catch (error) {
                this.mTypeList = []
                this.$showErrorMessage(
                    '頁面載入時出現錯誤，請稍後再試',
                    '若持續出現此問題，請聯繫資訊客服'
                )
            }
        }
        //目前頁面邏輯，admin不能在佣金查詢查看關帳的佣金資料，需要去發佣作業看
        // setCommissionStatus: async function () {
        //     // if (!this.$hasPermission('list')) return
        //     const parallel = Object.values(this.mTypeList).map((m) => {
        //         const mType = m.mType
        //         const payload = {
        //             mType: mType
        //         }
        //         return getCommissionPreClosingAPI(payload)
        //     })
        //     try {
        //         const allRes = await Promise.all(parallel)
        //         allRes.forEach((res, index) => {
        //             const mType = this.mTypeList[index].mType
        //             const status = res.data?.status
        //             this.mTypeCommissionStatusList[mType] = status
        //         })
        //     } catch (error) {
        //         this.mTypeList.forEach((m) => {
        //             const mType = m.mType
        //             this.mTypeCommissionStatusList[mType] = ''
        //         })
        //     }
        // }
    },
    data() {
        return {
            isLoadingTable: false,
            isShowZipPasswordAlert: false,
            isShowSuccessModal: false,
            mTypeList: [],
            filterPayload: {},
            filterLog: {},
            lastUpdateTime: '',
            resCommissionData: {
                dataSource: []
            },
            listData: []
            //目前頁面邏輯，admin不能在佣金查詢查看關帳的佣金資料，需要去發佣作業看
            // mTypeCommissionStatusList: {}
        }
    },
    async mounted() {
        await this.setMType()
        if (this.$isAdmin()) {
            //目前頁面邏輯，admin不能在佣金查詢查看關帳的佣金資料，需要去發佣作業看
            // await this.setCommissionStatus()
        }
    }
}
</script>

<style lang="scss" scoped>
.viewtitle-container {
    &.main-header-container {
        align-items: flex-end;
    }
    .component-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        .history-button {
            padding: 0 10px;

            @media screen and (max-width: 355px) {
                padding: 0 5px;

                :deep(.button-word) {
                    font-size: 10px;
                }
            }
        }
        :deep(.toolbox-button) {
            justify-content: flex-end;
        }
    }
}

.table-remark {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: $secondary-grey;
    font-size: 14px;

    .get-zip-password {
        margin-right: 20px;
        text-decoration: underline;
        cursor: pointer;
    }
}

:deep(.toolbox-container) {
    z-index: 7;
}
</style>
